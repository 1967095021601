@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Source Sans";
  src: url("public/fonts/SourceSans3-VariableFont_wght.ttf") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 25% 151%;
  font-display: swap;
}

/* we currently don't use italic fonts */
/* @font-face {
  font-family: "Source Sans";
  src: url("public/fonts/SourceSans3-Italic-VariableFont_wght.ttf") format("woff2") tech("variations");
  font-style: italic;
  font-weight: 200 900;
  font-stretch: 25% 151%;
  font-display: swap;
} */

html {
  @apply bg-primary-900/5;
  font-family:
    "Source Sans",
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

::selection {
  @apply bg-primary-900 text-white;
}

@media screen and (display-mode: standalone) {
  body {
    overscroll-behavior: none;
  }
}

@keyframes loading-bar {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow-fade {
  from {
    transform: scale(0.9) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateZ(0px);
    opacity: 1;
  }
}

::view-transition-old(main-container),
::view-transition-new(main-container) {
  height: auto;
  right: 0;
  left: auto;
  transform-origin: bottom center;
}

/* ::view-transition-old(main-container) {
  animation: 0.25s linear both shrink-x;
} */

::view-transition-new(main-container) {
  animation: 0.2s 0.2s ease both grow-fade;
}

/* Hey! Only use this for really long text blobs like
legal text. DO NOT use this as "base style" for a whole page */
.text-content {
  @apply hyphens-auto text-sm leading-normal text-slate-700;
}

.text-content h2 {
  @apply mb-2 mt-6 text-lg font-semibold;
}

.text-content h3 {
  @apply mb-2 mt-4 text-base font-semibold;
}

.text-content a {
  @apply text-primary-900 underline;
}

.text-content a[target="_blank"]::before {
  content: "↗ ";
  @apply mr-1 inline-block;
}

.text-content section {
  @apply mb-4;
}

.text-content ul {
  @apply list-inside list-disc py-2 pl-4;
}

.text-content ol {
  @apply list-inside list-decimal py-2 pl-4;
}
